import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../layouts/layout';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

const NotFoundPage = (): JSX.Element => {
  return (
    <div className="not-found-page">
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>App Bar - Mobile and Web Development Services</title>
      </Helmet>
      <Layout title="Page Not Found">
        <Header />
        <div className="not-found-page-content">
          <h1>Page Not Found :(</h1>
          <Link to="/">Return to Home</Link>
        </div>
        <Footer minimal />
      </Layout>
    </div>
  );
};

export default NotFoundPage;
